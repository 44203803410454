<template>
  <div v-if="isDataLoaded" class="calendar">
    <div class="pa-6 mb-15">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn fab icon @click="previousMonth">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <span
            >Les congés pour <strong>{{ displayMonth }} {{ year }} </strong>chez {{ agency }}</span
          >
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-btn fab icon @click="nextMonth">
            <v-icon>mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-badge dot color="EoleYellow" class="" inline></v-badge> <span>Congés en attente de validation</span>
        </v-col>
        <v-col cols="12" md="2"> <v-badge dot color="EoleBlue" inline></v-badge> <span>Congés approuvés</span> </v-col>
        <v-col cols="12" md="2"> <v-badge dot color="#8a8d93" inline></v-badge> <span>Formation</span> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-simple-table>
            <thead>
              <tr>
                <th>Nom</th>
                <th v-for="(day, index) in daysOfMonth" :key="index">{{ day.format("DD MMM") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(collaborator, index) in sortedCollaborators" :key="index">
                <td>{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
                <td
                  v-for="(day, index) in daysOfMonth"
                  :key="index"
                  :class="['custom-td-height ', getLeaveClass(collaborator, day)]"
                >
                  <v-row style="height: 0px">
                    <v-col
                      v-if="getLeaveClass(collaborator, day)"
                      :class="['white--text justify-center text-center ']"
                      cols="12"
                      md="5"
                    ></v-col>
                    <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                    <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                  </v-row>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Total</td>
                <td v-for="(day, index) in daysOfMonth" :key="index" :class="['custom-td-height']">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-row style="height: 0px" v-on="on" v-bind="attrs">
                        <v-col class="text-center" cols="12" md="12">{{ getCountUserInLeaveToday(day) }} </v-col>
                        <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                        <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                      </v-row>
                    </template>
                    <span>
                      <ul>
                        <li v-for="(collaborator, index) in getUsernameInLeaveToday(day)" v-bind:key="index">
                          {{ collaborator }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                  <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">
                    {{ days }} jours {{ getHolidayType(collaborator, day) }}
                  </div> -->
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="text-right"
          >Au mois de {{ displayMonth }} il y aura {{ totalNumberWork }} jour(s) travaillé(s)</v-col
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import "moment-business-days"
import Holidays from "date-holidays"

const hd = new Holidays()
hd.init("FR")

moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "Calendar",
  props: {
    collaborators: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      origin: "New York, NY",
      destination: "Los Angeles, CA",
      distanceResult: null,
      class: "",
      agency: "",
      year: moment().year(), // Ajout de l'année actuelle
      totalNumberWork: 0,
      month: moment().month() + 1, // Mois actuel
      leaves: [], // Données des congés
      daysOfMonth: [], // Jours du mois
      isDataLoaded: false,
      publicHolidays: [],
    }
  },
  computed: {
    displayMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
    sortedCollaborators() {
      return this.collaborators.sort((a, b) => {
        if (a.id_service === null) return 1
        if (b.id_service === null) return -1
        return a.id_service - b.id_service
      })
    },
  },
  watch: {
    month() {
      this.updateCalendar()
    },
    collaborators: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
  },
  methods: {
    calculateDistance() {
      const service = new google.maps.DistanceMatrixService()
      const request = {
        origins: [this.origin],
        destinations: [this.destination],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        key: "AIzaSyA4MciI_sdb-GzZMeVK7UvjDl8qeMGKpQU", // Remplacez par votre clé d'API
      }

      service.getDistanceMatrix(request, (response, status) => {
        if (status === "OK") {
          const result = response.rows[0].elements[0]
          this.distanceResult = {
            distance: result.distance,
            duration: result.duration,
          }
        } else {
          console.error("Erreur de requête : " + status)
        }
      })
    },
    getUsernamesById(id) {
      const collaborator = this.collaborators.find(collaborator => collaborator.id === id)

      return collaborator.lastname + " " + collaborator.firstname
    },
    getUsernameInLeaveToday(day) {
      const today = day.format("YYYY-MM-DD")
      let collaborators = []

      this.leaves.forEach(leave => {
        if (moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") && leave.state === 3) {
          collaborators.push(this.getUsernamesById(leave.collaboratorId))
        }
      })

      return collaborators
    },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    previousMonth() {
      if (this.month === 1) {
        // Si le mois actuel est janvier, passez à décembre de l'année précédente
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }
      this.updateCalendar()
    },
    nextMonth() {
      if (this.month === 12) {
        // Si le mois actuel est décembre, passez à janvier de l'année suivante
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.updateCalendar()
    },
    getCountUserInLeaveToday(day) {
      const today = day.format("YYYY-MM-DD")
      let length = 0

      this.leaves.forEach(leave => {
        if (moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") && leave.state === 3) {
          length++
        }
      })

      return length
    },
    updateCalendar() {
      this.generateDaysOfMonth()
      this.processLeaves()
      this.isDataLoaded = true
    },
    generateDaysOfMonth() {
      this.getPublicHolidaysFrance(this.year)
      this.totalNumberWork = 0
      this.daysOfMonth = []
      let startOfMonth = moment([this.year, this.month - 1]).startOf("month")
      let endOfMonth = moment([this.year, this.month - 1]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.publicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfMonth.push(startOfMonth.clone())
          this.totalNumberWork++
        }
        startOfMonth.add(1, "days")
      }
    },
    getLeaveClass(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay === 1) {
          if (leave.halfDayType === 1) {
            console.log("leave ma", leave)
            if (leave.state === 3) {
              this.class = "leave-half-morning-validated"
              return "leave-half-morning-validated"
            } else {
              this.class = "leave-half-morning-pending"
              return "leave-half-morning-pending"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType === 2) {
            console.log("leave ap", leave)
            if (leave.state === 3) {
              this.class = "leave-half-afternoon-validated"
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-pending"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 1) {
          this.class = "leave-start-nok"
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 1) {
          this.class = "leave-end-nok"
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 3) {
          this.class = "leave-start"
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 3) {
          this.class = "leave-end"
          return "leave-end"
        }

        if (moment(day).isSame(leave.startDate, "day") && leave.state === 4) {
          return "leave-start-training"
        }

        if (moment(day).isSame(leave.endDate, "day") && leave.state === 4) {
          return "leave-end-training"
        }

        if (leave.state === 4) {
          return "leave-middle-training"
        }

        if (leave.state === 3) {
          this.class = "leave-middle"
          return "leave-middle"
        }
        if (leave.state === 1) {
          this.class = "leave-middle-nok"
          return "leave-middle-nok"
        }
      }
      return ""
    },
    processLeaves() {
      this.leaves = this.collaborators.flatMap(collaborator =>
        collaborator.holidays
          .filter(holiday => {
            let startDate = moment(holiday.start_date)
            let endDate = moment(holiday.end_date)

            return startDate.month() === this.month - 1 || endDate.month() === this.month - 1
          })
          .map(holiday => ({
            collaboratorId: collaborator.id,
            startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
            endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
            days: holiday.number_of_days,
            state: holiday.state,
            isHalfDay: holiday.is_half_day,
            halfDayType: holiday.half_day_type,
          })),
      )
      console.log("collab", this.collaborators)
    },
  },
  mounted() {
    this.updateCalendar()
    this.agency = localStorage.getItem("agency")
  },
}
</script>

<style>
.leave-start-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-right: none;
}

.leave-middle-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-end-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
}

.leave-half-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-start-training .row .col-12 {
  padding-top: 0px;
}

.leave-middle-training .row .col-12 {
  padding-top: 0px;
}

.leave-end-training .row .col-12 {
  padding-top: 0px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid rgba(94, 86, 105, 0.14);
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: 0px solid rgba(94, 86, 105, 0.14);
}
.custom-td-height {
  height: 72px !important;
  width: 50px !important;
}
/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - Validé */
.leave-half-morning-validated {
  background: linear-gradient(to bottom, #001f47 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-validated .row {
  padding-bottom: 95px;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - En attente */
.leave-half-morning-pending {
  background: linear-gradient(to bottom, #fcc03c 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-pending .row {
  padding-bottom: 95px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - Validé */
.leave-half-afternoon-validated {
  background: linear-gradient(to top, #001f47 50%, transparent 50%);
  z-index: 999;
  content: "PM";
  /* don't change border color */
}

.leave-half-afternoon-validated .row .col-12 {
  padding-top: 15px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - En attente */
.leave-half-afternoon-pending {
  background: linear-gradient(to top, #fcc03c 50%, transparent 50%);
  content: "PM";
}

.leave-half-afternoon-pending .row {
  padding-top: 120px;
}

.leave-start-nok .row .col-12 {
  padding-top: 0px;
}

.leave-middle-nok .row .col-12 {
  padding-top: 0px;
}

.leave-end-nok .row .col-12 {
  padding-top: 0px;
}

.leave-start .row .col-12 {
  padding-top: 0px;
}

.leave-middle .row .col-12 {
  padding-top: 0px;
}

.leave-end .row .col-12 {
  padding-top: 0px;
}

.leave-half-morning-pending .row .col-12 {
  padding-top: 20px;
}

.leave-half-morning-validated .row .col-12 {
  padding-top: 20px;
}

.calendar {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  text-align: center;
}

thead th {
  background-color: #f5f5f5;
  color: #757575;
}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.on-leave {
  color: #f44336; /* Couleur rouge pour les jours de congé */
}

th:first-child,
td:first-child {
  text-align: left;
}

.leave-start-nok,
.leave-middle-nok,
.leave-end-nok {
  background-color: #fcc03c; /* Couleur pour les congés */
}

.leave-start-nok {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end-nok {
  border-left: none; /* Supprime la bordure gauche */
}

.leave-middle-nok {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* ... Autres styles ... */

/* Style de base pour les cellules de congé */
.leave-start,
.leave-middle,
.leave-end {
  background-color: #001f47; /* Couleur pour les congés */
}

/* Bordures arrondies pour le début et la fin */
.leave-start {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end {
  border-left: none; /* Supprime la bordure gauche */
}

/* Ajustements pour les cellules du milieu */
.leave-middle {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* Pour gérer les cas où le congé commence et se termine le même jour */

/* Style pour la dernière cellule de congé si elle est suivie d'une cellule non-congé */
.leave-end + td {
  border-left: 1px solid #e0e0e0; /* Remet la bordure gauche */
}

/* Style pour la première cellule normale après une série de congés */
td:not(.leave-middle):not(.leave-start):not(.leave-end) {
  border-left: 1px solid #e0e0e0; /* Assure que la cellule a une bordure gauche */
}
</style>
